// Next.js
import Link from '../Link'

// React
import { useState } from 'react'

// Styled Components
import { TabsWrapper, TabList, TabItem, TabLink } from './Tabs.styles'

// Types
import { TabsProps } from './Tabs.types'

const Tabs = ({
  background = 'grey',
  tabs,
  active,
  blur = false,
}: TabsProps) => {
  const [activeItem, setActiveItem] = useState('zobrazit vše')

  return (
    <TabsWrapper>
      <TabList background={background} blur={blur}>
        {tabs.map(tab => {
          return (
            <TabItem key={tab.id}>
              {tab.href ? (
                <Link href={tab.href}>
                  <TabLink
                    blur={blur}
                    active={active && activeItem === tab.title}
                    onClick={
                      tab.onClick
                        ? () => {
                            tab.onClick && tab.onClick()
                            setActiveItem(tab.title)
                          }
                        : () => {
                            setActiveItem(tab.title)
                          }
                    }
                  >
                    {tab.title}
                  </TabLink>
                </Link>
              ) : (
                <TabLink
                  as='div'
                  active={active && activeItem === tab.title}
                  onClick={
                    tab.onClick
                      ? () => {
                          tab.onClick && tab.onClick()
                          setActiveItem(tab.title)
                        }
                      : () => {
                          setActiveItem(tab.title)
                        }
                  }
                >
                  {tab.title}
                </TabLink>
              )}
            </TabItem>
          )
        })}
      </TabList>
    </TabsWrapper>
  )
}
export default Tabs
