// Artsy Fresnel
import { Media, MediaContextProvider } from '../../utils/media'

// React components (svg)
import Chevron2Icon from '../svgs/icons/Chevron2Icon'

// Styled Components
import { ScrollButtonWrapper, ChevronIconWrapper } from './ScrollButton.styles'

// Types
import { ScrollButtonProps } from './ScrollButton.types'

const ScrollButton = ({ href = '#vision' }: ScrollButtonProps) => {
  return (
    <ScrollButtonWrapper as='a' href={href} aria-label='Scroll tlačítko'>
      <MediaContextProvider disableDynamicMediaQueries>
        <Media lessThan='lg'>
          <ChevronIconWrapper
            animate={{ y: [0, 75, 75, 75, 0, 0], opacity: [1, 1, 0, 0, 0, 1] }}
            transition={{ repeat: Infinity, duration: 2.5 }}
          >
            <Chevron2Icon />
          </ChevronIconWrapper>
        </Media>
      </MediaContextProvider>
      <MediaContextProvider disableDynamicMediaQueries>
        <Media greaterThanOrEqual='lg'>
          <ChevronIconWrapper
            animate={{
              y: [0, 115, 115, 115, 0, 0],
              opacity: [1, 1, 0, 0, 0, 1],
            }}
            transition={{ repeat: Infinity, duration: 2.5 }}
          >
            <Chevron2Icon />
          </ChevronIconWrapper>
        </Media>
      </MediaContextProvider>
    </ScrollButtonWrapper>
  )
}

export default ScrollButton
