const Chevron2Icon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='27.788'
      height='27.788'
      viewBox='0 0 27.788 27.788'
    >
      <g data-name='Group 95' transform='translate(.53)'>
        <path
          fill='none'
          stroke='#fff'
          strokeWidth='1.5'
          d='M0 13.245l13.482 13.482 13.245-13.245'
          data-name='Path 8'
        ></path>
      </g>
    </svg>
  )
}
export default Chevron2Icon
