import styled, { css } from 'styled-components'

// Framer Motion
import { motion } from 'framer-motion'

// Types
import { ButtonProps, RegionListProps } from './RegionSelect.types'

export const RegionSelectWrapper = styled.div`
  isolation: isolate;
  position: relative;
  width: fit-content;
  z-index: 25;
`

export const Button = styled.button<ButtonProps>`
  margin: auto;
  cursor: pointer;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  outline: none;
  min-width: 230px;
  padding: 6px 18px;
  border-radius: 44px;
  border: 1px solid ${({ theme }) => theme.colors.regionSelect.border};
  background-color: ${({ theme }) => theme.colors.regionSelect.background};
  transition: background-color 0.3s;

  // Chevron icon
  & > div > svg {
    position: relative;
    top: -1px;
    height: 13px;
  }

  @media (${({ theme }) => theme.mediaQueries.md}) {
    min-width: 398px;
    padding: 12px 18px;

    // Location Icon
    & > svg {
      height: 39px;
    }

    // Chevron icon
    & > div > svg {
      height: 15px;
    }
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.regionSelect.borderHover};
    background-color: ${({ theme }) =>
      theme.colors.regionSelect.backgroundHover};
  }

  &:active {
    border: 1px solid ${({ theme }) => theme.colors.regionSelect.borderActive};
    background-color: ${({ theme }) =>
      theme.colors.regionSelect.backgroundActive};
  }

  ${({ darkBackground }) =>
    darkBackground &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.regionSelect.borderDarkBg};
      background-color: ${({ theme }) =>
        theme.colors.regionSelect.backgroundDarkBg};
      backdrop-filter: blur(17.44339942932129px);

      span {
        color: ${({ theme }) => theme.colors.regionSelect.textDarkBg};
      }

      // Location Icon
      & > svg {
        path {
          fill: ${({ theme }) => theme.colors.regionSelect.textDarkBg};
        }
      }

      // Chevron icon
      & > div > svg {
        path {
          stroke: ${({ theme }) => theme.colors.regionSelect.textDarkBg};
        }
      }

      &:hover {
        border: 1px solid
          ${({ theme }) => theme.colors.regionSelect.borderHoverDarkBg};
        background-color: ${({ theme }) =>
          theme.colors.regionSelect.backgroundHoverDarkBg};

        span {
          color: ${({ theme }) => theme.colors.regionSelect.textHoverDarkBg};
        }

        // Chevron icon
        & > div > svg {
          path {
            stroke: ${({ theme }) => theme.colors.regionSelect.textHoverDarkBg};
          }
        }
      }

      &:active {
        border: 1px solid
          ${({ theme }) => theme.colors.regionSelect.borderActiveDarkBg};
        background-color: ${({ theme }) =>
          theme.colors.regionSelect.backgroundActiveDarkBg};
        backdrop-filter: blur(50px);

        span {
          color: ${({ theme }) => theme.colors.regionSelect.textActiveDarkBg};
        }

        // Location Icon
        & > svg {
          path {
            fill: ${({ theme }) => theme.colors.regionSelect.textActiveDarkBg};
          }
        }

        // Chevron icon
        & > div > svg {
          path {
            stroke: ${({ theme }) =>
              theme.colors.regionSelect.textActiveDarkBg};
          }
        }
      }
    `}
`

export const SelectedText = styled.span`
  color: ${({ theme }) => theme.colors.regionSelect.text};
  font-size: ${({ theme }) => 14 / theme.fontSizes.root + 'rem'};
  font-weight: 500;
  transition: color 0.3s;

  @media (${({ theme }) => theme.mediaQueries.md}) {
    font-size: ${({ theme }) => (18 - 2) / theme.fontSizes.root + 'rem'};
  }
`

export const RegionList = motion(styled.ul<RegionListProps>`
  position: absolute;
  isolation: isolate;
  z-index: 25;
  top: 100%;
  ${({ up }) =>
    up &&
    css`
      top: initial;
      bottom: 100%;
    `}
  left: 0;
  right: 0;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 253px;
  margin: 0 auto;
  gap: 7px;
  color: ${({ theme }) => theme.colors.contactRegionSelect.dropdown.text};
  background-color: ${({ theme }) =>
    theme.colors.contactRegionSelect.dropdown.background};
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.16);
`)

export const RegionItem = styled.li`
  cursor: pointer;
  text-align: center;
  border-radius: 57px;
  padding: 14px;
  position: relative;
  background-color: ${({ theme }) =>
    theme.colors.contactRegionSelect.dropdown.item.background};

  &::after {
    content: '';
    position: absolute;
    bottom: -3px;
    margin: auto;
    left: 0;
    right: 0;
    width: 205px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grey90};
  }

  &:last-of-type {
    &::after {
      display: none;
    }
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.contactRegionSelect.dropdown.item.backgroundHover};
  }

  &:active {
    background-color: ${({ theme }) =>
      theme.colors.contactRegionSelect.dropdown.item.backgroundActive};
  }
`

export const ChevronIconWrapper = styled(motion.div)``
