import styled, { css } from 'styled-components'
import { PageTitle } from '../../styled-components/shared/common'

// Types
import { HeroWrapperProps } from './Hero.types'

export const HeroWrapper = styled.div<HeroWrapperProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
  padding: 174px 38px 0;
  width: calc(100% - 2px);

  ${({ about }) =>
    about &&
    css`
      padding-bottom: 315px;
    `};

  margin-top: -83px;
  background: ${({ backgroundImage }) =>
    `linear-gradient(rgba(33, 33, 33, 0.5) 0%, rgba(33, 33, 33, 0.1) 59.93%, rgba(33, 33, 33, 0.1) 67.59%, rgba(0, 0, 0, 0.5) 100%), url(${backgroundImage}) center top / cover no-repeat`};

  @media (${({ theme }) => theme.mediaQueries.md}) {
    margin-top: -175px;
    padding: 270px 60px 60px;
    background: ${({ backgroundImage }) =>
      `linear-gradient(rgba(33, 33, 33, 0.5) 0%, rgba(33, 33, 33, 0.1) 59.93%, rgba(33, 33, 33, 0.1) 67.59%, rgba(0, 0, 0, 0.5) 100%), url(${backgroundImage}) center top / cover no-repeat`};

    &::before {
      position: absolute;
      z-index: 0;
      left: -1px;
      top: -1px;
      content: '';
      width: 20%;
      height: 40%;
      background: ${({ theme }) => theme.colors.grey80};
      clip-path: polygon(33% 0, 0 0, 0 100%);
    }

    &::after {
      position: absolute;
      z-index: 0;
      right: -1px;
      bottom: -1px;
      content: '';
      width: 20%;
      height: 50%;
      background: ${({ theme }) => theme.colors.grey80};
      clip-path: polygon(100% 0, 60% 100%, 100% 100%);
    }
  }

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    padding-right: 120px;
    padding-left: 120px;

    &::before {
      width: 70%;
      height: calc(100% + 2px);
      bottom: -1px;
      top: -1px;
      clip-path: polygon(0 0, 41% 0, 7% 100%, 0% 100%);
    }

    &::after {
      width: 50%;
      height: 80%;
    }
  }

  @media (${({ theme }) => theme.mediaQueries.xxl}) {
    padding-right: 270px;
    padding-left: 270px;
    padding-bottom: ${({ career }) => (career ? '87px' : '284px')};
  }
`

export const Title = styled.h1`
  font-size: ${({ theme }) => (41 - 2) / theme.fontSizes.root + 'rem'};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.hero.title};
  max-width: 280px;
  line-height: 1;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    font-size: ${({ theme }) => (100 - 2) / theme.fontSizes.root + 'rem'};
    max-width: 800px;
  }
`

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.hero.text};
  font-size: ${({ theme }) => (18 - 2) / theme.fontSizes.root + 'rem'};
  font-weight: 300;
  max-width: 300px;
  line-height: 25px;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    font-size: ${({ theme }) => (24 - 2) / theme.fontSizes.root + 'rem'};
    max-width: 720px;
    line-height: 30px;
  }
`

export const ScrollButtonWrapper = styled.div`
  position: relative;
  bottom: -42px;

  @media (${({ theme }) => theme.mediaQueries.md}) {
    bottom: initial;
  }
`

export const SelectTitle = styled(PageTitle)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => (25 - 2) / theme.fontSizes.root + 'rem'};
  text-align: center;
  align-self: center;

  @media (${({ theme }) => theme.mediaQueries.xl}) {
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => (48 - 2) / theme.fontSizes.root + 'rem'};
  }
`

export const Container = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Label = styled.div`
  font-family: 'neue-haas-grotesk-display', sans-serif;
  line-height: 1.2;
  text-transform: uppercase;
  font-size: ${({ theme }) => (18 - 2) / theme.fontSizes.root + 'rem'};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 100;

  @media (${({ theme }) => theme.mediaQueries.xl}) {
    font-size: ${({ theme }) => (25 - 2) / theme.fontSizes.root + 'rem'};
  }
`
