// Next.js
import { useRouter } from '../../hooks/router'

// React
import { useState, useRef } from 'react'

// Framer Motion
import { AnimatePresence } from 'framer-motion'

// React components (svg)
import LocationIcon from '../svgs/icons/LocationIcon'
import ChevronIcon from '../svgs/icons/ChevronIcon'

// Hooks imports
import useClickOutside from '../../hooks/useClickOutside'

// Styled Components
import {
  ProfessionSelectWrapper,
  Button,
  SelectedText,
  ProfessionList,
  ProfessionItem,
  ChevronIconWrapper,
} from './ProfessionSelect.styles'

// Types
import { ProfessionSelectProps } from './ProfessionSelect.types'
import useDictionaryLine from '../../hooks/useDictionary'
import dictionary from '../../utils/dictionary'

const ProfessionSelect = ({
  darkBackground,
  up = false,
  positions,
}: ProfessionSelectProps) => {
  const placeholder = useDictionaryLine(dictionary.placeholders.choosePosition)
  const router = useRouter()
  const ref = useRef(null)

  const [value, setValue] = useState(placeholder)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  useClickOutside(ref, () => {
    setIsDropdownVisible(false)
  })

  return (
    <ProfessionSelectWrapper ref={ref}>
      <Button
        onClick={() => {
          setIsDropdownVisible(prev => !prev)
        }}
        darkBackground={darkBackground}
      >
        <div></div>
        <SelectedText>{value}</SelectedText>
        <ChevronIconWrapper
          initial={{ rotate: up ? 180 : 0 }}
          animate={
            isDropdownVisible
              ? {
                  rotate: up ? 0 : 180,
                }
              : { rotate: up ? 180 : 0 }
          }
        >
          <ChevronIcon />
        </ChevronIconWrapper>
      </Button>
      <AnimatePresence>
        {isDropdownVisible && (
          <ProfessionList
            up={up}
            initial={{
              opacity: 0,
              rotateX: -15,
            }}
            animate={{
              opacity: 1,
              rotateX: 0,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{
              opacity: 0,
              rotateX: -15,
              transition: {
                duration: 0.2,
              },
            }}
          >
            {positions?.map(position => (
              <ProfessionItem
                key={position.id}
                onClick={() => {
                  router.push({
                    pathname: '/hledame',
                    query: { position: position.attributes.name },
                  })
                  setIsDropdownVisible(false)
                }}
              >
                {position.attributes.name}
              </ProfessionItem>
            ))}
          </ProfessionList>
        )}
      </AnimatePresence>
    </ProfessionSelectWrapper>
  )
}
export default ProfessionSelect
