import styled, { css } from 'styled-components'

// Types
import { TabsListProps, TabLinkProps } from './Tabs.types'

export const TabsWrapper = styled.div``

export const TabList = styled.ul<TabsListProps>`
  display: flex;
  flex-direction: column;
  padding: 6px 36px;
  max-width: 80%;
  background-color: ${({ theme, background }) =>
    background === 'white'
      ? theme.colors.tabs.backgroundWhite
      : theme.colors.tabs.backgroundGrey};
  border-radius: 0 118px 118px 0;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    flex-direction: row;
    border-radius: 57px;
    width: fit-content;
    max-width: initial;
    padding: 3px 42px 24px;
    align-items: flex-end;
    text-align: center;
  }

  ${({ blur }) =>
    blur &&
    css`
      background: transparent;
      /* Note: currently only Safari supports backdrop-filter */
      backdrop-filter: blur(14px);
      --webkit-backdrop-filter: blur(14px);
      background-color: rgba(255, 255, 255, 0.21);
    `}
`

export const TabItem = styled.li``

export const TabLink = styled.a<TabLinkProps>`
  position: relative;
  display: block;
  cursor: pointer;
  padding: 12px;
  font-size: ${({ theme }) => (16 - 2) / theme.fontSizes.root + 'rem'};
  color: ${({ theme }) => theme.colors.tabs.text};
  text-decoration: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: block;
    height: 100%;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.tabs.line};
  }

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.tabs.text};

    &:before {
      width: 3px;
      left: -1px;
    }
  }

  ${({ blur }) =>
    blur &&
    css`
      color: ${({ theme }) => theme.colors.white};

      &:before {
        background-color: ${({ theme }) => theme.colors.white};
      }

      &:hover {
        color: ${({ theme }) => theme.colors.white};
      }
    `}

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    font-size: ${({ theme }) => (18 - 2) / theme.fontSizes.root + 'rem'};
    padding: 30px 10px;

    &:before {
      width: 100%;
      height: 1px;
      top: initial;
      left: 0;
      right: 0;
    }

    &:hover {
      &:before {
        width: 100%;
        height: 3px;
        left: 0;
        right: 0;
        bottom: -1px;
      }
    }
  }

  @media (min-width: 1820px) {
    padding: 30px 18px;
  }

  ${({ active }) =>
    active &&
    css`
      text-decoration: none;
      color: ${({ theme }) => theme.colors.tabs.text};

      &:before {
        width: 3px;
        left: -1px;
      }

      @media (${({ theme }) => theme.mediaQueries.lg}) {
        &:before {
          width: 100%;
          height: 3px;
          left: 0;
          right: 0;
          bottom: -1px;
        }
      }
    `}
`
