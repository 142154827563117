// Styled Components
import { LocationIconWrapper } from './LocationIcon.styles'

const LocationIcon = () => {
  return (
    <LocationIconWrapper
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 18.578 27.77'
    >
      <path
        fill='#575757'
        d='M14.723 2.113A9.272 9.272 0 002.115 14.655L9.78 28.471a.582.582 0 001.016 0l7.667-13.82a9.252 9.252 0 00-3.74-12.538zm-4.435 13.205a4.556 4.556 0 114.556-4.556 4.556 4.556 0 01-4.556 4.556z'
        data-name='Path 26'
        transform='translate(-1 -1)'
      ></path>
    </LocationIconWrapper>
  )
}
export default LocationIcon
