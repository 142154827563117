import styled from 'styled-components'

// Framer Motion
import { motion } from 'framer-motion'

export const ScrollButtonWrapper = styled.button`
  cursor: pointer;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.scrollButton.border};
  background-color: ${({ theme }) => theme.colors.scrollButton.background};
  border-radius: 66px;
  width: 60px;
  height: 145px;
  display: flex;
  justify-content: center;
  padding: 15px 5px;

  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.scrollButton.backgroundHover};
    backdrop-filter: blur(50px);
  }

  &:active {
    background-color: ${({ theme }) =>
      theme.colors.scrollButton.backgroundActive};
    backdrop-filter: blur(50px);
  }

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    width: 83px;
    height: 200px;
  }
`

export const ChevronIconWrapper = styled(motion.div)`
  svg {
    path {
      stroke: ${({ theme }) => theme.colors.scrollButton.icon};
    }
  }

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    svg {
      width: 39px;
      height: 39px;
    }
  }
`
