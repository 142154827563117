// Next.js
import { useRouter } from '../../hooks/router'

// React
import { useState, useRef } from 'react'

// Framer Motion
import { AnimatePresence } from 'framer-motion'

// React components (svg)
import LocationIcon from '../svgs/icons/LocationIcon'
import ChevronIcon from '../svgs/icons/ChevronIcon'

// Hooks imports
import useClickOutside from '../../hooks/useClickOutside'

// Styled Components
import {
  RegionSelectWrapper,
  Button,
  SelectedText,
  RegionList,
  RegionItem,
  ChevronIconWrapper,
} from './RegionSelect.styles'

// Types
import { RegionSelectProps } from './RegionSelect.types'

// Regions
import { REGIONS, REGIONS_TRANSLATION_MAP } from '../../utils/constants'
import useDictionaryLine from '../../hooks/useDictionary'
import dictionary from '../../utils/dictionary'

const RegionSelect = ({
  darkBackground,
  up = false,
  defaultValue,
}: RegionSelectProps) => {
  const ref = useRef(null)
  const router = useRouter()
  const chooseRegionPlaceholder = useDictionaryLine(
    dictionary.placeholders.chooseRegion,
  )

  const [value, setValue] = useState(
    defaultValue
      ? REGIONS_TRANSLATION_MAP[defaultValue.toLowerCase().replace(/_/g, '-')]
      : chooseRegionPlaceholder,
  )
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  useClickOutside(ref, () => {
    setIsDropdownVisible(false)
  })

  return (
    <RegionSelectWrapper ref={ref}>
      <Button
        onClick={() => {
          setIsDropdownVisible(prev => !prev)
        }}
        darkBackground={darkBackground}
      >
        <LocationIcon />
        <SelectedText>{value}</SelectedText>
        <ChevronIconWrapper
          initial={{ rotate: up ? 180 : 0 }}
          animate={
            isDropdownVisible
              ? {
                  rotate: up ? 0 : 180,
                }
              : { rotate: up ? 180 : 0 }
          }
        >
          <ChevronIcon />
        </ChevronIconWrapper>
      </Button>
      <AnimatePresence>
        {isDropdownVisible && (
          <RegionList
            up={up}
            initial={{
              opacity: 0,
              rotateX: -15,
            }}
            animate={{
              opacity: 1,
              rotateX: 0,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{
              opacity: 0,
              rotateX: -15,
              transition: {
                duration: 0.2,
              },
            }}
          >
            {REGIONS.map((region, id) => {
              return (
                <RegionItem
                  key={id}
                  onClick={() => {
                    setValue(region)
                    setIsDropdownVisible(false)
                    router.push(
                      {
                        pathname: `/kontakt/${REGIONS_TRANSLATION_MAP[region]}`,
                        hash: 'provozovny-a-sberne-dvory',
                      },
                      undefined,
                      { scroll: false },
                    )
                  }}
                >
                  {region}
                </RegionItem>
              )
            })}
          </RegionList>
        )}
      </AnimatePresence>
    </RegionSelectWrapper>
  )
}
export default RegionSelect
