// Next.js
import Link from '../Link'

// React components
import Button from '../Button'
import ScrollButton from '../ScrollButton'
import RegionSelect from '../RegionSelect'
import ProfessionSelect from '../ProfessionSelect'
import Tabs from '../Tabs'

// Artsy Fresnel

import { Media, MediaContextProvider } from '../../utils/media'

// Styled Components
import {
  HeroWrapper,
  Title,
  Text,
  ScrollButtonWrapper,
  SelectTitle,
  Container,
  Label,
} from './Hero.styles'
import Spacer from '../../styled-components/shared/Spacer'
import { LightText } from '../../styled-components/shared/common'

// Types
import { Api } from '../../types/api'

import { TABS_ABOUT, TABS_CAREER } from '../../utils/constants'
import useDictionaryLine from '../../hooks/useDictionary'
import dictionary from '../../utils/dictionary'
import { splitTextBy } from '../../utils/dom'

const Hero = ({
  title = '',
  url,
  description,
  image,
  label = '',
  tabs = TABS_ABOUT,
  page = 'homepage',
  positions,
}: Api.Hero) => {
  const aboutLocale = useDictionaryLine(dictionary.mainNavigation.about)
  const lookUpLocale = useDictionaryLine(dictionary.searchLocation)

  return (
    <HeroWrapper
      career={page === 'career' || page === 'about-ave'}
      backgroundImage={
        image?.data?.attributes.url || '/images/article-placeholder.webp'
      }
    >
      {page === 'about' && (
        <>
          <MediaContextProvider disableDynamicMediaQueries>
            <Media greaterThanOrEqual='lg'>
              <Label>{label}</Label>
              <Spacer size={36} axis='vertical' />
            </Media>
          </MediaContextProvider>
        </>
      )}
      <Title>{title}</Title>
      <Spacer size={24} axis='vertical' />
      <Text>{description}</Text>
      <Spacer size={30} axis='vertical' when={{ lg: 48 }} />
      {page === 'homepage' && (
        <Link href={url || '/'} passHref legacyBehavior>
          <Button link variant='outlinedSecondary'>
            {aboutLocale}
          </Button>
        </Link>
      )}

      {page === 'career' && (
        <Link href={url || '/'} passHref legacyBehavior>
          <Button link href='' variant='contained'>
            {label}
          </Button>
        </Link>
      )}

      {page === 'about-ave' && (
        <Link href={url || '/'} passHref legacyBehavior>
          <Button link href='' variant='contained'>
            {label}
          </Button>
        </Link>
      )}
      <Spacer size={72} axis='vertical' when={{ md: 96, lg: 78 }} />
      <Container>
        <ScrollButtonWrapper>
          <ScrollButton
            href={
              page === 'career' || page === 'about-ave'
                ? '#proc-ave'
                : '#vision'
            }
          />
        </ScrollButtonWrapper>

        {page === 'homepage' && (
          <MediaContextProvider disableDynamicMediaQueries>
            <Media greaterThanOrEqual='md'>
              <Spacer size={60} axis='vertical' when={{ xl: 120 }} />
              <SelectTitle>
                {splitTextBy({ line: lookUpLocale, Right: LightText })}
              </SelectTitle>
              <Spacer size={48} axis='vertical' when={{ xl: 105 }} />
              <RegionSelect darkBackground={true} />
            </Media>
          </MediaContextProvider>
        )}

        {page === 'career' && (
          <MediaContextProvider disableDynamicMediaQueries>
            <Media greaterThanOrEqual='md'>
              <Spacer size={60} axis='vertical' when={{ xl: 120 }} />
              <SelectTitle>
                {splitTextBy({ by: ' ', line: title, Left: LightText })}
              </SelectTitle>
              <Spacer size={48} axis='vertical' when={{ xl: 105 }} />
              <ProfessionSelect darkBackground={true} positions={positions} />
            </Media>
          </MediaContextProvider>
        )}

        {page === 'about-ave' && (
          <MediaContextProvider disableDynamicMediaQueries>
            <Media greaterThanOrEqual='md'>
              <Spacer size={60} axis='vertical' when={{ xl: 120 }} />
              <SelectTitle>
                {splitTextBy({ by: ' ', line: title, Left: LightText })}
              </SelectTitle>
              <Spacer size={48} axis='vertical' when={{ xl: 105 }} />
              <ProfessionSelect darkBackground={true} positions={positions} />
            </Media>
          </MediaContextProvider>
        )}

        {page === 'about' && (
          <MediaContextProvider disableDynamicMediaQueries>
            <Media greaterThanOrEqual='lg'>
              <Spacer size={36} axis='vertical' when={{ xl: 108 }} />
              <Tabs tabs={tabs} blur={true} />
            </Media>
          </MediaContextProvider>
        )}

        {page === 'career' && (
          <MediaContextProvider disableDynamicMediaQueries>
            <Media greaterThanOrEqual='lg'>
              <Spacer size={36} axis='vertical' when={{ xl: 108 }} />
              <Tabs tabs={TABS_CAREER} blur={true} />
            </Media>
          </MediaContextProvider>
        )}

        {(page === 'about' || page === 'career') && (
          <MediaContextProvider disableDynamicMediaQueries>
            <Media lessThan='lg'>
              <Spacer size={350} axis='vertical' />
            </Media>
          </MediaContextProvider>
        )}
      </Container>
    </HeroWrapper>
  )
}
export default Hero
